<template>
	<div class="bordered mt16">
		<Title>老年人健康情况管理</Title>
		<div class="info mt12">
			<div>*以下数据统计老年人自理能力评估合格占比。</div>
			<div>总数:<countTo :startVal="0" :endVal="valueFun" :duration="1000" class="value"></countTo></div>
		</div>
		<el-table
			:header-cell-style="{ padding: '0', height: '42px' }"
			:cell-style="{ padding: '0', height: '42px' }"
			row-class-name="hover-row"
			:data="tableDataFun"
			stripe
			style="background-color: transparent; width: 94%; margin: 12px 3%;font-size: 16px;"
		>
			<el-table-column align="center" class-name="first" prop="data1" label="" width="70">
			</el-table-column>
			<el-table-column align="center" class-name="odd" prop="data2" label="自理">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data2" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
			<el-table-column align="center" class-name="even" prop="data3" label="轻度依赖">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data3" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
			<el-table-column align="center" class-name="odd" prop="data4" label="中度依赖">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data4" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
			<el-table-column align="center" class-name="even" prop="data5" label="不能自理">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data5" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';

export default {
	components: { Title, countTo },
	data() {
		return {
      CountAnimationDuration,
			value: 2145,
			tableData: [
				{
					data1: '进餐',
					data2: 85,
					data3: 62,
					data4: 70,
					data5: 90,
				},
				{
					data1: '梳洗',
					data2: 65,
					data3: 88,
					data4: 42,
					data5: 30,
				},
				{
					data1: '穿衣',
					data2: 85,
					data3: 62,
					data4: 70,
					data5: 90,
				},
				{
					data1: '如厕',
					data2: 65,
					data3: 88,
					data4: 42,
					data5: 30,
				},
				{
					data1: '活动',
					data2: 85,
					data3: 62,
					data4: 70,
					data5: 90,
				},
			],
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    valueFun(){
      return this.value / (this.mapLevel + 1)
    },
    tableDataFun(){
      return this.tableData.map(s => {
        return {
          ...s,
          data2: s.data2 / (this.mapLevel + 1),
          data3: s.data3 / (this.mapLevel + 1),
          data4: s.data4 / (this.mapLevel + 1),
          data5: s.data5 / (this.mapLevel + 1)
        }
      })
    }
	},
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.info{
	padding: 0 12px;
}
/deep/ .el-table {
	background-color: transparent !important;
}

/deep/ .el-table,
.el-table__expanded-cell {
	background-color: transparent; //这是设置透明背景色
}
/deep/ .el-table td.el-table__cell, 
/deep/ .el-table th.el-table__cell.is-leaf{
	border-bottom: 2px solid rgba(5, 41, 92, .6) !important;
}
/deep/ .el-table::before{
	background-color: transparent !important; 
}

/deep/ .el-table__header-wrapper {
	tr {
		background-color: transparent;
	}
	.odd {
		font-size: 14px;
	}
	.even {
		font-size: 14px;
	}

	.el-table__cell {
		color: white;
		background-color: #153b6b;
		border-bottom-color: #081735;
	}
}

/deep/ .el-table__body-wrapper {
	.el-table__row {
		background-color: #0d2e5a !important;
	}

	.el-table__row--striped {
		.el-table__cell {
			background-color: #153b6b !important;
		}
	}
}

/deep/ .hover-row > td {
	background-color: initial !important;
}

/deep/ .first {
	div {
		color: rgba(255, 255, 255, 0.8);
	}
}

/deep/ .odd {
	color: #1fbdff;
	font-size: 18px;
}

/deep/ .even {
	color: #00f0ff;
	font-size: 18px;
}

.info {
	display: flex;
	align-items: center;

	.value {
		color: $main-text;
		font-size: 18px;
	}
}
</style>
