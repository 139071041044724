<template>
  <div class="bordered mt16">
    <Title>预防接种统计</Title>
    <BaseEchart :option="option" style="margin-top: -10px; width: 100%; height: 440px"></BaseEchart>
  </div>
</template>
<script>
import Title from '../components/Title';
import BaseEchart from '@/components/BaseEchart';
import { mapState } from 'vuex';
export default {
  components: { Title, BaseEchart },
  data() {
    return {
      option: undefined,
      data:[
      { value: 1048, name: '乙肝' },
      { value: 735, name: '卡介苗' },
      { value: 580, name: '脊灰灭活' },
      { value: 484, name: '脊灰减毒活' },
      { value: 300, name: '白破疫' },
      { value: 128, name: '甲肝减毒活' },
      { value: 40, name: '百白破疫' },
      { value: 555, name: '麻-风' },
      { value: 489, name: '麻腮风' },
      { value: 872, name: '脊乙脑减毒活' },
      { value: 1024, name: 'A群流脑多糖' },
      { value: 446, name: 'C群流脑多糖' },
      ],
      echartData:[]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.option = {
        color: ['#F9A168', '#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3'],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 0,
          padding: [0, 0, 20, 0],
          formatter: function (name) {
            return '{a|' + name + '}'
          },
          icon: 'circle',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'white',
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 80,
                fontSize: 12
              },
            },
          },
        },
        grid: {
          top: 0,
        },
        graphic: {
          elements: [
            {
              type: 'circle',
              shape: {
                r: 138,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 52,
            },
            {
              type: 'circle',
              shape: {
                r: 78,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 110,
            },
            {
              type: 'image',
              style: {
                image: require('../assets/pie-bg2.jpg'),
                width: 128,
                height: 128,
              },
              left: 'center',
              top: 125,
            },
            {
              type: 'text',
              left: '43.5%',
              top: 160,
              style: {
                fill: '#ffffff',
                text: '接种总数',
                font: '14px sans-serif',
                textAlign: 'center',

              },
            },
            {
              type: 'text',
              left: 'middle',
              bounding: 'raw',
              left: '41.5%',
              top: 190,
              style: {
                fill: '#00F7F9',
                text: '21,786',
                font: '26px sans-serif',
                textAlign: 'center',
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            top: -60,
            left: 'center',
            width: 260,
            radius: ['80%', '100%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#020C1D',
              borderWidth: 3,
            },
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: '乙肝' },
              { value: 735, name: '卡介苗' },
              { value: 580, name: '脊灰灭活' },
              { value: 484, name: '脊灰减毒活' },
              { value: 300, name: '白破疫' },
              { value: 128, name: '甲肝减毒活' },
              { value: 40, name: '百白破疫' },
              { value: 555, name: '麻-风' },
              { value: 489, name: '麻腮风' },
              { value: 872, name: '脊乙脑减毒活' },
              { value: 1024, name: 'A群流脑多糖' },
              { value: 446, name: 'C群流脑多糖' },
            ],
          },
        ],
      };
    },
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    valueByLevel(){
      return this.data.map(s => {
        return {
          ...s,
          value:  Math.round(s.value - ((this.mapLevel + 1)*30)),
        }
      })
    }
  },
  watch:{
    mapLevel:{
      immediate:true,
      handler:function(){
        this.option = {
        color: ['#F9A168', '#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3'],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 0,
          padding: [0, 0, 20, 0],
          formatter: function (name) {
            return '{a|' + name + '}'
          },
          icon: 'circle',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'white',
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 80,
                fontSize: 12
              },
            },
          },
        },
        grid: {
          top: 0,
        },
        graphic: {
          elements: [
            {
              type: 'circle',
              shape: {
                r: 138,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 52,
            },
            {
              type: 'circle',
              shape: {
                r: 78,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 110,
            },
            {
              type: 'image',
              style: {
                image: require('../assets/pie-bg2.jpg'),
                width: 128,
                height: 128,
              },
              left: 'center',
              top: 125,
            },
            {
              type: 'text',
              left: '43.5%',
              top: 160,
              style: {
                fill: '#ffffff',
                text: '接种总数',
                font: '14px sans-serif',
                textAlign: 'center',

              },
            },
            {
              type: 'text',
              // left: 'middle',
              bounding: 'raw',
              left: '43%',
              top: 190,
              style: {
                fill: '#00F7F9',
                text: `${this.mapLevel==0?"21786":Math.round(Number(21786)/(this.mapLevel+1))}`,
                font: '26px sans-serif',
                textAlign: 'center',
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            top: -60,
            left: 'center',
            width: 260,
            radius: ['80%', '100%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#020C1D',
              borderWidth: 3,
            },
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: this.valueByLevel,
          },
        ],
      };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
</style>